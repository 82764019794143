import React, { ReactElement } from "react";
import { Icon } from "./icon/Icon";

export type IconSelectorProps = {
    name: string;
}

/**
 * Component to select the correct icon
 */
export const IconSelector = (props: IconSelectorProps): JSX.Element => {
    props.name = props.name.toLowerCase();

    if (props.name === "dashboard") {
        return <img src="~/dist/resources/images/dashboard.svg"/>
    }
    else if (props.name === "viewer") {
        return <Icon size="18px" icon="map" />
    }
    else if (props.name === "maptour") {
        return <img src="~/dist/resources/images/maptour.svg"/>
    }
    else if (props.name === "praatmee") {
        return <img src="~/dist/resources/images/praatmee.svg"/>
    }
    else {
        return <Icon size="18px" icon="map" />
    }
};
