import * as React from "react";
import { useAuth } from "react-oidc-context";
import { App } from "../../types/App";

export type CreateAppItemProps = {

    /**
     * Key of the element in the list
     */
    key: number;

    /**
     * App object
     */
    app: App;

    /**
     * Handler for clicking on an app
     * @param id - id of the app
     */
    clickHandler: (id: string) => void;
}

export const CreateAppItem = ({ key, app, clickHandler }: CreateAppItemProps) => {
    const [icon, setIcon] = React.useState<string>();
    const auth = useAuth();

    /**
     * Get icon for app
     * @param icon - icon url
     */
    const getIcon = (icon: string) => {
        if (!icon) {
            return;
        }

        fetch(icon, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            }
        }).then(blob => {
            const url = URL.createObjectURL(blob!);
            setIcon(url);
        });
    }

    React.useEffect(() => {
        getIcon(app.icon);
    }, []);

    return <li key={key} className="icon-geoapps menu-item">
        <a onClick={() => clickHandler(app.id)}>
            <span role="none" className="icon">
                <img src={icon} />
            </span>
            <span className="flex">
                <span className="title">{app.name}</span>
                <span className="description">{app.description}</span>
            </span>
        </a>
    </li>;
}
