import { useEffect, useState } from 'react';

/**
 * Hook to check whether the app should be in dark or light mode
 */
export const useTheme = () => {
    const [theme, setTheme] = useState<"dark-mode" | "light">(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark-mode" : "light");

    const updateTheme = (event: MediaQueryListEvent) => {
        const newTheme = event.matches ? "dark-mode" : "light";
        setTheme(newTheme);
    }

    // Set theme based on user preference
    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', updateTheme);
        
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', updateTheme);
        }
    }, []);

    // Set the theme in the app
    useEffect(() => {
        const html = document.querySelector('html');
        if (!html)
            return;

        if (theme === "dark-mode") {
            html.classList.add("dark-mode");
        }
        else {
            html.classList.remove("dark-mode");
        }
    }, [theme]);

    return theme;
};
