import { useTranslation } from "react-i18next";
import React from "react";
import { goToNewPage } from "../functions/Globalfunctions";
import { Icon } from "../icon/Icon";
import { Label } from "../label/Label";
import { IconSelector } from "../IconSelector";
import { ImageSelector } from "../ImageSelector";

export type AppProps = {
	/**
	 * React key
	 */
	key?: string;
	/**
	 * Contains data needed to build up the Apps component
	 */
	appData?: any;
	/**
	* Display by tile or list
	*/
	displayBy: "card-tile" | "card-list";
};

// Apps returns <div> with properties of relevent app
export const App = (props: AppProps): JSX.Element => {
	const app = props.appData;
	const { t } = useTranslation();

	return (
		<div className="card">
			{props.displayBy === "card-tile" &&
				<div className="hide-label card-label">
					<div className="label-wrap">
						<Label />
					</div>
					<div className="label-content">
						<div className="icon-apptype">
							<IconSelector name={app.appType.name} />
						</div>
						<span>{app.appType.name}</span>
					</div>
				</div>
			}
			<div role="none" className="card-image">
				<ImageSelector name={app.appType.name} thumbnail={app.thumbnail} />
			</div>
			<div className="card-bottom">
				<div className="card-details">
					<div className="card-title flex gap-10">
						<div className="icon-apptype">
							<IconSelector name={app.appType.name} />
						</div>
						<h2>{app.name}</h2>
					</div>
					<div className="card-description-buttons">
						<div className="app-description">{app.description}</div>
						<div className="card-buttons">
							{props.displayBy === "card-list" &&
								<div className="label-box label-content">
									<div className="icon-list">
										<IconSelector name={app.appType.name} />
									</div>
									{app.appType.name}
								</div>
							}
							<button
								className="button button-primary button-icon"
								onClick={() => goToNewPage(app.url)}
							>
								<Icon size="16" icon="eye" className="icon-button" />
								{t("VIEW")}
							</button>
							{app.editUrl && <button
								className="button button-secondary button-icon button-edit"
								onClick={() => goToNewPage(app.editUrl)}
							>
								<Icon size="16" icon="gear" className="icon-button" />
								{t("EDIT")}
							</button>}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};