import React, { useState, useEffect, useRef } from "react";
import { Group } from "./apps/Group";
import { LoadingIndicator } from "./LoadingIndicator";
import { useGeoApps } from "@mapgear/geoapps-ui-framework";
import { LoadingType } from "../types/LoadingType";
import { FilterProps } from "../types/FilterProps";
import { DisplayProps } from "../types/DisplayProps";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";

export type AppTableProps = {
    /**
     * Filter state
     */
    filterParam: FilterProps;
    /**
     * Display state
     */
    displayParam: DisplayProps;
};

export const AppTable = (props: AppTableProps): JSX.Element => {
    const { geoapps } = useGeoApps();
    const { t } = useTranslation();
    const [init, setInit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const scrollParentElement = useRef<HTMLDivElement>(null);
    /**
     * Amount of apps load in when infinitescroll is triggerd
     */
    const limit = 10;

    // Get apps on initial load
    useEffect(() => {
        getApps();
    }, []);

    // Get apps on props change
    useEffect(() => {
        if (init) {
            getApps();
        }
    }, [props.filterParam]);

    /**
     * Function resets the scroll bar to the top
     */
    const scrollReset = () => {
        if (scrollParentElement.current) {
            scrollParentElement.current.scrollTo(0, 0);
        }
    };

    /**
     * Download apps from the server
     */
    const getApps = (offset: number = 0, append: boolean = false) => {

        // Set loading when not appending
        if (!append) {
            setLoading(true);
            setLoadingFailed(false);
            scrollReset();
        }

        const params = {
            direction: 'asc',
            search: props.filterParam.search,
            orderBy: "Name",
            limit: limit,
            offset: offset,
            groupBy: props.filterParam.groupBy
        };

        /**
         * Concat groups when the names are the same if they dont he will show up as a new group
         * @param groups 
         * @returns 
         */
        const concatGroups = (groups: any[]) => {
            const combinedgroups = data;
            if (combinedgroups[combinedgroups.length - 1].identifier === groups[0].identifier) {
                combinedgroups[combinedgroups.length - 1].apps = combinedgroups[combinedgroups.length - 1].apps.concat(groups[0].apps);
                groups = groups.slice(1);
            }
            return combinedgroups.concat(groups);
        };

        // Get apps from the server
        geoapps.Apps.GetList(params).then((response: { groups: { apps: any[] }[]; total: number; }) => {

            // Add new apps if append is true, The else is when you type.
            let newData = response.groups;
            if (append) {
                newData = concatGroups(newData);
            }

            // Check if there are more apps to load
            const appCount = newData.reduce((acc, group) => acc + group.apps.length, 0);
            setHasMore(appCount < response.total);

            // Set new data
            setData(newData);
            setLoading(false);

            if (!init) {
                setInit(true);
            }
        })
            .catch(error => () => {
                console.error(error);
                setLoadingFailed(true);
            });
    };

    /**
     * Get rendered list of app groups
     */
    const groups = data.map((apps, index) => (
        <Group key={`g${index}`} displayBy={props.displayParam.displayBy} groupIdx={index} groupName={apps.identifier} appData={apps} />
    ));

    /**
     * Determine what to render; whether page is busy loading, the loading has failed, 
     * No apps were loaded at all or the page is done loading and returns the apps/groups.
     */
    const getContent = () => {
        if (loading) {
            return <LoadingIndicator indicator={LoadingType.LOADING} text={t("APPS_LOADING")} />;
        } else if (loadingFailed) {
            return <LoadingIndicator indicator={LoadingType.ERROR} text={t("APPS_ERROR")} />;
        } else if (data.length === 0) {
            return <LoadingIndicator indicator={LoadingType.ERROR} text={t("NO_APPS")} />;
        } else {
            return <InfiniteScroll threshold={1500} pageStart={0} initialLoad={false} loadMore={handleLoadMore} hasMore={hasMore} useWindow={false} getScrollParent={() => scrollParentElement.current}>
                {groups}
            </InfiniteScroll>
        }
    }

    /**
     * Trigger to load more data into the start page
     * @param page 
     */
    const handleLoadMore = (page: number) => {
        const offset = limit * page;
        getApps(offset, true);
    }

    return (
        <div className="app-overview" ref={scrollParentElement}>
            <div className="container">
                {getContent()}
            </div>
        </div>
    );
};