import * as React from "react";
import IcoMoon from "react-icomoon";
import iconSet from "./selection.json";

export const Icon = (props: {
  color?: string;
  size: string | number;
  icon: string;
  iconSet?: Object;
  className?: string;
}) => {
  const { color, size = "100%", icon, className = "" } = props;
  return (
    <IcoMoon
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
    />
  );
};
