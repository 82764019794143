import { useTranslation } from "react-i18next";
import React, {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import { DisplayProps } from "../../types/DisplayProps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faGrid, faDisplay } from '@fortawesome/pro-solid-svg-icons';
import { Icon } from "../icon/Icon";

export type DisplayByProps = {
  /**
   * Display by state setter
   */
  setDisplayByParam: Dispatch<SetStateAction<DisplayProps>>;
};

export type DisplayByParameterType = "card-tile" | "card-list";

export const DisplayBy = (props: DisplayByProps): JSX.Element => {
  const [display, setDisplay] = useState<boolean>(false);
  const [value, setValue] = useState<DisplayByParameterType | undefined>(undefined);
  const createRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  /**
   * Show the CreateAppList
   */
  const toggleDisplay = () => {
    setDisplay(!display);
  };

  // Value of the display button will save in sessionStorage every time when value is changed
  useEffect(() => {
    if (!value)
      return;

    sessionStorage.setItem("displayValue", value);
    props.setDisplayByParam((prevState) => ({ ...prevState, displayBy: value}));
  }, [value]);

  useEffect(() => {
    // Hide display by list on click outside of display by button
    document.addEventListener("click", detectOutsideClick);

    return () => {
      document.removeEventListener("click", detectOutsideClick);
    };
  }, []);

  // If sessionStorage exits, use the value, else do nothing and use the original value
  useEffect(() => {
    const displayValue = sessionStorage.getItem("displayValue") as DisplayByParameterType;
    if (displayValue) {
      setValue(displayValue);
    } else {
      setValue("card-tile");
    }
  }, []);

  /**
   * Detects click outside of display by button
   * Hides displayby list
   */
  const detectOutsideClick = (e: MouseEvent) => {
    if (createRef.current && !createRef.current?.contains(e.target as Node)) {
      setDisplay(false);
    }
  };

  return (

    <div className="display-by">
      <button
        className="button button-icon"
        ref={createRef}
        onClick={toggleDisplay}
        title={t("DISPLAY")}
      >
        <FontAwesomeIcon icon={faDisplay} />
        <span>{t("DISPLAY")}</span>
      </button>
      {display && (
        <div className="dropdown-menu">
          <button
            onClick={() => setValue("card-tile")}
            className={value === "card-tile" ? "active" : ""}
          >
            <Icon size="16px" icon="tick" className="icon" />
            <FontAwesomeIcon icon={faGrid} /></button>
          <button
            onClick={() => setValue("card-list")}
            className={value === "card-list" ? "active" : ""}
          >
            <Icon size="16px" icon="tick" className="icon" />
            <FontAwesomeIcon icon={faList} /></button>
        </div>
      )}
    </div>
  );
};
