import { use } from "i18next";
import React from "react";
import { useTheme } from "./hooks/useTheme";

export type ImageSelectorProps = {
    /**
     * Name of the app
     */
    name: string;

    /**
     * The thumbnail of the app (if available)
     */
    thumbnail: string;
}

/**
 * Component to select the correct icon
 */
export const ImageSelector = (props: ImageSelectorProps): JSX.Element => {
    const theme = useTheme();
    const appType = props.name.toLowerCase();
    const thumbnail = props.thumbnail;

    switch (appType) {
        case "dashboard":
            return (
                theme === "dark-mode" ?
                    <img className="card-svg" src="~/dist/resources/images/dashboard_dark.svg" /> :
                    <img className="card-svg" src="~/dist/resources/images/dashboard_light.svg" />
            )
        case "viewer":
            return (
                thumbnail ?
                    <img loading="lazy" src={`${thumbnail}?width=700&height=350&mode=crop`} /> :
                    theme === "dark-mode" ?
                        <img className="card-svg" src="~/dist/resources/images/viewer_dark.svg" /> :
                        <img className="card-svg" src="~/dist/resources/images/viewer_light.svg" />
            )
        case "maptour":
            return (
                theme === "dark-mode" ?
                    <img className="card-svg" src="~/dist/resources/images/maptour_dark.svg" /> :
                    <img className="card-svg" src="~/dist/resources/images/maptour_light.svg" />
            )
        case "praatmee":
            return (
                theme === "dark-mode" ?
                    <img className="card-svg" src="~/dist/resources/images/praatmee_dark.svg" /> :
                    <img className="card-svg" src="~/dist/resources/images/praatmee_light.svg" />
            )
        default:
            return <img className="card-svg" src="~/dist/resources/images/map-background.svg" />
    }
};