import { useTranslation } from "react-i18next";
import React, {
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from "react";
import { FilterProps } from "../../types/FilterProps";
import { Icon } from "../icon/Icon";
import { faGrid2 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type SearchProps = {
  /**
   * GroupBy state setter
   */
  setGroupByParam: Dispatch<SetStateAction<FilterProps>>;
};

export type GroupByParameterType = "project.name" | "appType.name";

export const GroupBy = (props: SearchProps): JSX.Element => {
  const [display, setDisplay] = useState<boolean>(false);
  const createRef = useRef<HTMLButtonElement>(null);
  const [value, setValue] = useState<GroupByParameterType>("project.name");
  const { t } = useTranslation();

  /**
   * Show the CreateAppList
   */
  const toggleDisplay = () => {
    setDisplay(!display);
  };

  /**
   * Set GroupBy parameter
   */
  useEffect(() => {
    props.setGroupByParam((prevState) => ({ ...prevState, groupBy: value }));
  }, [value]);

  useEffect(() => {
    // Hide groupby list on click outside of group by button
    document.addEventListener("click", detectOutsideClick);

    return () => {
      document.removeEventListener("click", detectOutsideClick);
    };
  }, []);

  /**
   * Detects click outside of group by button
   * Hides groupby list
   */
  const detectOutsideClick = (e: MouseEvent) => {
    if (createRef.current && !createRef.current?.contains(e.target as Node)) {
      setDisplay(false);
    }
  };

  return (
    <div className="group-by">
      <button
        className="button button-icon"
        ref={createRef}
        onClick={toggleDisplay}
        title={t("GROUP")}
      >
        <FontAwesomeIcon icon={faGrid2} />
        <span>{t("GROUP")}</span>
      </button>
      {display && (
        <div className="dropdown-menu">
          <button
            onClick={() => setValue("project.name")}
            className={value === "project.name" ? "active" : ""}
          >
            <Icon size="16px" icon="tick" className="icon" />
            {t("PROJECT")}
          </button>
          <button
            onClick={() => setValue("appType.name")}
            className={value === "appType.name" ? "active" : ""}
          >
            <Icon size="16px" icon="tick" className="icon" />
            {t("TYPE")}
          </button>
        </div>
      )}
    </div>
  );
};